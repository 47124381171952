var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "NewFleetCheckView px-2 mt-3 px-lg-5",
      attrs: { "data-test-id": "fleet-check-view" },
    },
    [
      _c("h1", { staticClass: "pb-1" }, [_vm._v(" Fleet check ")]),
      _c(
        "div",
        { staticClass: "emobg-color-ink-light emobg-font-weight-bold mb-1" },
        [
          _vm._v(
            " " +
              _vm._s(
                `Start Date/Time: ${
                  _vm.fleetCheckStartedOn
                    ? _vm.formatUtc(
                        _vm.fleetCheckStartedOn,
                        _vm.DATE_FORMAT.defaultExtended,
                        _vm.operatorTimezone
                      )
                    : _vm.FALLBACK_MESSAGE.noData
                }`
              ) +
              " "
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "emobg-color-ink-light emobg-font-weight-bold mb-3" },
        [_vm._v(" " + _vm._s(`Stations: ${_vm.stationNames}`) + " ")]
      ),
      _c(
        "ui-alert",
        {
          staticClass: "d-block mb-4",
          attrs: {
            color: _vm.COLORS.primary,
            icon: _vm.ICONS.infoFull,
            "data-test-id": "search_help_text-alert",
          },
        },
        [
          _c("p", { staticClass: "emobg-font-small emobg-color-ink" }, [
            _c("span", { staticClass: "emobg-font-weight-semibold" }, [
              _vm._v("Search"),
            ]),
            _vm._v(": Use the keyboard shortcut "),
            _c("span", { staticClass: "emobg-font-weight-semibold" }, [
              _vm._v("Ctrl + F"),
            ]),
            _vm._v(
              " to search a registration number or any other text in this page "
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "px-3 py-4 mb-3 emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-white",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between mb-3" },
            [
              _c(
                "div",
                { staticClass: "emobg-font-weight-bold emobg-font-default" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isEmpty(_vm.vehiclesWithExceptions)
                          ? "No vehicles with exceptions detected"
                          : `Vehicles with exceptions detected (${_vm.vehiclesWithExceptions.length})`
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "ui-button",
                {
                  staticClass: "ExportCsv",
                  attrs: {
                    color: _vm.GRAYSCALE.inkLight,
                    face: _vm.FACES.outline,
                    size: _vm.SIZES.small,
                    loading: _vm.isDownloading,
                    disabled: _vm.isEmpty(_vm.vehiclesWithExceptions),
                    "data-test-id": "vehicle_with_exceptions_export-button",
                  },
                  on: { clickbutton: _vm.exportExceptionList },
                },
                [
                  _c("ui-icon", {
                    attrs: {
                      icon: _vm.ICONS.download,
                      size: _vm.SIZES.small,
                      hover: "",
                    },
                  }),
                  _c("div", { staticClass: "ml-3" }, [
                    _vm._v(" Export exception list "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isFleetCheckLoading
            ? _c("ui-loader")
            : _c(
                "MuiValidationWrapper",
                { ref: "form", staticClass: "w-100" },
                [
                  !_vm.isEmpty(_vm.vehiclesWithExceptions)
                    ? _c("MuiTable", {
                        staticClass: "mx-0 mb-4",
                        attrs: {
                          "data-set": _vm.vehiclesWithExceptions,
                          "no-data-label": _vm.FALLBACK_MESSAGE.noData,
                          "content-cells": _vm.vehicleWithExceptionContentCells,
                          "data-test-id": "vehicles_with_exceptions-table",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "justify-content-end d-flex" },
                    [
                      !_vm.isTableValid
                        ? _c(
                            "ui-alert",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                color: _vm.COLORS.danger,
                                icon: _vm.ICONS.infoFull,
                              },
                            },
                            [
                              _c("span", { staticClass: "emobg-font-small" }, [
                                _vm._v(" Please complete all missing fields "),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "ui-button",
                        {
                          attrs: { "data-test-id": "confirm-button" },
                          on: { clickbutton: _vm.onConfirmNewFleetCheck },
                        },
                        [_vm._v(" Confirm fleet check ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c(
            "div",
            { staticStyle: { height: "0", overflow: "hidden" } },
            [
              _c("ExceptionVehiclesPdf", {
                attrs: { vehicles: _vm.vehiclesWithExceptions },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "px-3 py-4 emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-white",
        },
        [
          _c(
            "div",
            { staticClass: "emobg-font-weight-bold emobg-font-default mb-3" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isEmpty(_vm.vehiclesWithoutExceptions)
                      ? "No vehicles without exceptions detected"
                      : `Vehicles with no exceptions detected (${_vm.vehiclesWithoutExceptions.length})`
                  ) +
                  " "
              ),
            ]
          ),
          _c("MuiTable", {
            staticClass: "mx-0 mb-4",
            attrs: {
              "data-set": _vm.vehiclesWithoutExceptions,
              "content-cells": _vm.vehicleWithoutExceptionContentCells,
              "no-data-label": _vm.FALLBACK_MESSAGE.noData,
              "is-loading": _vm.isFleetCheckLoading,
              "data-test-id": "vehicles_with_no_exceptions-table",
            },
          }),
        ],
        1
      ),
      _c(
        "MuiModal",
        _vm._b(
          {
            attrs: {
              size: _vm.SIZES.small,
              "data-test-id": "leave_page_feedback-modal",
            },
            on: {
              "modal-closed": function ($event) {
                _vm.isLeaveModalOpen = false
              },
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "py-3 emobg-color-ink-light emobg-font-line-height-large",
                        attrs: { "data-test-id": "warning" },
                      },
                      [
                        _vm._v(
                          " Your changes will not be saved and you will have to start the Fleet check again. "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-end p-3" },
                      [
                        _c(
                          "ui-button",
                          {
                            attrs: {
                              color: _vm.GRAYSCALE.inkLight,
                              face: _vm.FACES.text,
                              "data-test-id": "cancel-button",
                            },
                            on: {
                              clickbutton: function ($event) {
                                _vm.isLeaveModalOpen = false
                              },
                            },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          "ui-button",
                          {
                            staticClass: "ml-4",
                            attrs: {
                              color: _vm.COLORS.danger,
                              "data-test-id": "navigate-button",
                            },
                            on: { clickbutton: _vm.navigate },
                          },
                          [_vm._v(" Yes, leave ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.isLeaveModalOpen,
              callback: function ($$v) {
                _vm.isLeaveModalOpen = $$v
              },
              expression: "isLeaveModalOpen",
            },
          },
          "MuiModal",
          _vm.leaveModalConfig,
          false
        )
      ),
      _c(
        "MuiModal",
        _vm._b(
          {
            attrs: {
              size: _vm.SIZES.small,
              "data-test-id": "leave_page_feedback-modal",
            },
            on: {
              "modal-closed": function ($event) {
                _vm.isConfirmModalOpen = false
              },
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "py-3 emobg-color-ink-light emobg-font-line-height-large",
                        attrs: { "data-test-id": "warning" },
                      },
                      [
                        _vm._v(
                          " Once confirmed you won’t be able to edit or delete the created fleet check. "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-end p-3" },
                      [
                        _c(
                          "ui-button",
                          {
                            attrs: {
                              color: _vm.GRAYSCALE.inkLight,
                              face: _vm.FACES.text,
                              "data-test-id": "cancel-button",
                            },
                            on: {
                              clickbutton: function ($event) {
                                _vm.isConfirmModalOpen = false
                              },
                            },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          "ui-button",
                          {
                            staticClass: "ml-4",
                            attrs: {
                              loading: _vm.isSubmittingFleetCheck,
                              "data-test-id": "navigate-button",
                            },
                            on: { clickbutton: _vm.doConfirmFleetCheck },
                          },
                          [_vm._v(" Confirm ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.isConfirmModalOpen,
              callback: function ($$v) {
                _vm.isConfirmModalOpen = $$v
              },
              expression: "isConfirmModalOpen",
            },
          },
          "MuiModal",
          _vm.confirmModalConfig,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }