var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "RefinementDate pb-2",
      class: `${_vm.isMobileLayout ? "col-md-12 col-lg-6" : "col-12"}`,
    },
    [
      _c(
        "div",
        {
          staticClass: "row mt-2",
          class: `${
            _vm.isMobileLayout
              ? "justify-content-start"
              : "justify-content-between"
          }`,
        },
        [
          _c(
            "div",
            {
              class: [
                `col-md-${_vm.isMobileLayout ? "6" : "12"}`,
                { "mb-4": !_vm.isMobileLayout },
              ],
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "emobg-font-weight-bold emobg-font-small pb-1 w-100",
                },
                [_vm._v(" From (Date/Time) ")]
              ),
              _c("ui-datetimepicker", {
                staticClass: "w-100",
                attrs: {
                  size: _vm.SIZES.small,
                  "data-test-id": "filter-search_from-input_date_time",
                },
                domProps: { date: _vm.dates.startedOnAfter },
                on: {
                  datechanged: ({ detail }) =>
                    _vm.onDateChanged(
                      detail,
                      _vm.DATE_FILTER_KEYS.startedOnAfter
                    ),
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { class: `col-md-${_vm.isMobileLayout ? "6" : "12"}` },
            [
              _c(
                "div",
                {
                  staticClass:
                    "emobg-font-weight-bold emobg-font-small pb-1 w-100",
                },
                [_vm._v(" To (Date/Time) ")]
              ),
              _c("ui-datetimepicker", {
                staticClass: "w-100",
                attrs: {
                  size: _vm.SIZES.small,
                  "data-test-id": "filter-search_from-input_date_time",
                },
                domProps: { date: _vm.dates.startedOnBefore },
                on: {
                  datechanged: ({ detail }) =>
                    _vm.onDateChanged(
                      detail,
                      _vm.DATE_FILTER_KEYS.startedOnBefore
                    ),
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.isActive
        ? _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "ui-button",
                {
                  staticClass: "ml-n2",
                  attrs: {
                    face: _vm.FACES.text,
                    "data-test-id": "clear_dates-button",
                  },
                  on: { clickbutton: _vm.clearDates },
                },
                [_vm._v(" Clear dates ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }