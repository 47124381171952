<script>
export default {
  name: 'RefinementDate',
  props: {
    isMobileLayout: {
      type: Boolean,
      default: true,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dates: {
        startedOnAfter: null,
        startedOnBefore: null,
      },
    };
  },
  computed: {
    isActive() {
      return this.dates.startedOnAfter || this.dates.startedOnBefore;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.dates.startedOnAfter = null;
        this.dates.startedOnBefore = null;
      }
    },
  },
  created() {
    this.DATE_FILTER_KEYS = {
      startedOnAfter: 'startedOnAfter',
      startedOnBefore: 'startedOnBefore',
    };
  },
  methods: {
    onDateChanged(date, key) {
      this.dates[key] = date;
      this.$emit('on:dateChaged', [{ date, key }]);
    },
    clearDates() {
      this.dates.startedOnAfter = null;
      this.dates.startedOnBefore = null;
      this.$emit('on:dateChaged', [
        {
          date: null,
          key: this.DATE_FILTER_KEYS.startedOnAfter,
        },
        {
          date: null,
          key: this.DATE_FILTER_KEYS.startedOnBefore,
        },
      ]);
    },
  },
};
</script>

<template>
  <div
    class="RefinementDate pb-2"
    :class="`${ isMobileLayout ? 'col-md-12 col-lg-6' : 'col-12'}`"
  >
    <div
      class="row mt-2"
      :class="`${isMobileLayout ? 'justify-content-start' : 'justify-content-between'}`"
    >
      <div :class="[`col-md-${ isMobileLayout ? '6': '12' }`, { 'mb-4': !isMobileLayout }]">
        <div class="emobg-font-weight-bold emobg-font-small pb-1 w-100">
          From (Date/Time)
        </div>
        <ui-datetimepicker
          :date.prop="dates.startedOnAfter"
          :size="SIZES.small"
          data-test-id="filter-search_from-input_date_time"
          class="w-100"
          @datechanged="({ detail }) => onDateChanged(detail, DATE_FILTER_KEYS.startedOnAfter)"
        />
      </div>
      <div :class="`col-md-${ isMobileLayout ? '6': '12' }`">
        <div class="emobg-font-weight-bold emobg-font-small pb-1 w-100">
          To (Date/Time)
        </div>
        <ui-datetimepicker
          :date.prop="dates.startedOnBefore"
          :size="SIZES.small"
          data-test-id="filter-search_from-input_date_time"
          class="w-100"
          @datechanged="({ detail }) => onDateChanged(detail, DATE_FILTER_KEYS.startedOnBefore)"
        />
      </div>
    </div>
    <div
      v-if="isActive"
      class="row"
    >
      <ui-button
        :face="FACES.text"
        class="ml-n2"
        data-test-id="clear_dates-button"
        @clickbutton="clearDates"
      >
        Clear dates
      </ui-button>
    </div>
  </div>
</template>

