import toLower from 'lodash/toLower';

import { FALLBACK_MESSAGE, sentenceCase } from '@emobg/web-utils';

import { GEOFENCE_DATA } from '../const/newFleetCheck.const';
import ExceptionReason from '../components/ExceptionReason';

import VehicleIdentifier from '../components/VehicleIdentifier';
import TooltipHeader from '../../../components/TooltipHeader';

import { HEADER_TOOLTIPS } from '../const/tooltips.const';

export const contentCells = ({ hasExceptions = true } = {}) => [
  {
    title: 'Vehicle identifier',
    component: VehicleIdentifier,
    props: ({ regNumber, vin }) => ({ plateNumber: regNumber, vinNumber: vin }),
    width: 200,
  },
  {
    title: 'Model',
    property: 'model',
    width: 150,
  },
  {
    title: 'Colour',
    property: 'colour',
    width: 150,
    transformValue: sentenceCase,
  },
  {
    title: 'Geofence data',
    property: 'positionState',
    width: 150,
    transformValue: value => GEOFENCE_DATA[toLower(value)] || FALLBACK_MESSAGE.dash,
    headerComponent: TooltipHeader,
    headerProps: () => ({ tooltip: HEADER_TOOLTIPS.geofence }),
  },
  {
    title: 'Greenway data',
    property: 'greenwayData',
    width: 150,
    headerComponent: TooltipHeader,
    headerProps: () => ({ tooltip: HEADER_TOOLTIPS.greenway }),
  },
  {
    title: `${hasExceptions ? 'Select exception reasons' : 'Exception reasons'}`,
    component: ExceptionReason,
    props: ({ vin }) => ({ vehicleVin: vin, hasExceptions }),
    width: 200,
  },
];

export const pdfContentCells = [
  {
    title: 'Vehicle id.',
    component: VehicleIdentifier,
    props: ({ regNumber, vin }) => ({ plateNumber: regNumber, vinNumber: vin, showLink: false }),
    width: 160,
  },
  {
    title: 'Model',
    property: 'model',
    width: 110,
  },
  {
    title: 'Colour',
    property: 'colour',
    width: 40,
    transformValue: sentenceCase,
  },
  {
    title: 'Geof. data',
    property: 'positionState',
    width: 100,
    transformValue: value => GEOFENCE_DATA[toLower(value)] || FALLBACK_MESSAGE.dash,
  },
  {
    title: 'GWY data',
    property: 'greenwayData',
    width: 80,
  },
  {
    title: '-',
    property: 'vin',
    transformValue: () => '',
    width: 180,
  },
];
