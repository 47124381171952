var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "StationList" }, [
    !_vm.isEmpty(_vm.stations)
      ? _c(
          "div",
          _vm._l(_vm.stations, function (station) {
            return _c("p", { key: station.id, staticClass: "mb-1" }, [
              _vm._v(" " + _vm._s(`${station.id} ${station.name}`) + " "),
            ])
          }),
          0
        )
      : _c("span", [_vm._v(_vm._s(_vm.FALLBACK_MESSAGE.noData))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }