<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import concat from 'lodash/concat';

import { mapActions, mapState } from 'vuex';
import {
  DATE_FORMAT,
  DELAY,
  formatUtc,
  TIME_ZONE,
} from '@emobg/web-utils';
import { MuiTable } from '@emobg/vue-base';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { downloadPdf } from '@/domains/Carrental/utils/downloadPdf';
import { contentCells } from '../config/fleetCheckPdfContentCells';
import { FLEET_CHECK_PDF_ID } from '../const/fleetCheck.const';

export default {
  name: 'DownloadPdf',
  components: {
    MuiTable,
  },
  props: {
    fleetCheckId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDownloading: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carRental.fleetCheck, {
      fleetCheckDetails: state => get(state, 'fleetCheckDetails.data') || {},
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorCountryCode: state => state.operators.countryCode,
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    stationNames() {
      return map(this.fleetCheckDetails.summary.stations, station => `${station.id} ${station.name}`).join(', ');
    },
    vehicles() {
      return concat(this.fleetCheckDetails.vehiclesWithException, this.fleetCheckDetails.vehicles);
    },
  },
  created() {
    this.FLEET_CHECK_PDF_ID = FLEET_CHECK_PDF_ID;
    this.DATE_FORMAT = DATE_FORMAT;
    this.contentCells = contentCells;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carRental.fleetCheck, ['getFleetCheck']),
    async downloadFleetCheck() {
      this.isDownloading = true;
      this.$notify({
        message: 'The fleet check download has started. This process might take a while.',
        delay: DELAY.extraLong,
        type: NOTIFICATION_TYPES.info,
      });
      await this.getFleetCheck(this.fleetCheckId);
      const summaryCreatedOn = formatUtc(this.fleetCheckDetails.summary.createdOn, DATE_FORMAT.dateTime, this.operatorTimezone).replace(' ', '-');
      const summaryStationsId = map(get(this.fleetCheckDetails, 'summary.stations') || [], station => station.id);
      const filename = `fleet_check-${this.operatorCountryCode}-${summaryCreatedOn}-${summaryStationsId.join('_')}`;
      await downloadPdf(FLEET_CHECK_PDF_ID, filename);
      this.isDownloading = false;
    },
    getVehiclesDataLength(vehiclesKey) {
      const vehicles = get(this.fleetCheckDetails, vehiclesKey) || [];
      return vehicles.length;
    },
    isEmpty,
    formatUtc,
  },
};
</script>

<template>
  <div class="DownloadPdf">
    <ui-button
      :color="GRAYSCALE.inkLight"
      :face="FACES.outline"
      :loading="isDownloading"
      square
      data-test-id="download_pdf-button"
      @clickbutton="downloadFleetCheck"
    >
      <ui-icon
        :size="ICONS_SIZES.small"
        :color="GRAYSCALE.inkLight"
        :icon="ICONS.download"
        class="d-flex flex-column align-center"
      />
    </ui-button>
    <div
      v-if="!isEmpty(fleetCheckDetails)"
      class="DownloadPdf__pdfWrapper"
      style="height: 0; overflow: hidden;"
    >
      <div
        :id="FLEET_CHECK_PDF_ID"
        class="emobg-background-color-white"
      >
        <div class="row no-gutters">
          <div class="col-12 emobg-color-white emobg-background-color-corporate-primary-darkest px-3 py-2 mb-4">
            <h4>Automated fleet check</h4>
          </div>
          <div class="col-12 px-3 mb-2">
            <div class="row">
              <div class="col-6 emobg-font-small">
                <div class="mb-1 emobg-body-2">
                  Fleet check done by:
                </div>
                {{ fleetCheckDetails.summary.author }}
              </div>
              <div class="col-6 text-right emobg-font-small">
                <div class="emobg-font-small mb-1">
                  <span class="mr-1 emobg-body-2">Start Date/Time:</span>
                  <span>{{ formatUtc(fleetCheckDetails.summary.createdOn, DATE_FORMAT.defaultExtended, operatorTimezone) }}</span>
                </div>
                <div class="emobg-font-small">
                  <span class="mr-1 emobg-body-2">End Date/Time:</span>
                  <span>{{ formatUtc(fleetCheckDetails.summary.finishedOn, DATE_FORMAT.defaultExtended, operatorTimezone) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 px-3 mb-2">
            <div class="mb-1 emobg-body-2">
              Station(s):
            </div>
            {{ stationNames }}
          </div>
          <div class="col-12 px-3 emobg-font-weight-bold emobg-font-small mb-4">
            {{ `Veh. with exceptions detected (${getVehiclesDataLength('vehiclesWithException')}) | Veh. with no exceptions detected (${getVehiclesDataLength('vehicles')})` }}
          </div>
          <div class="col-12 px-3">
            <MuiTable
              data-test-id="vehicles-table"
              :data-set="vehicles"
              :content-cells="contentCells"
              class="mx-0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

