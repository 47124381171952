var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "VehicleIdentifier" }, [
    _vm.plateNumber
      ? _c(
          "div",
          [
            _vm.showLink
              ? _c("VehicleLink", {
                  attrs: {
                    "plate-number": _vm.plateNumber,
                    "vin-number": _vm.vinNumber,
                    target: "_blank",
                  },
                })
              : _c("span", [_vm._v(_vm._s(_vm.plateNumber))]),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("span", { staticClass: "mr-1" }, [
              _vm._v(_vm._s(_vm.vinNumber)),
            ]),
            _vm.showLink
              ? _c(
                  "ui-tooltip",
                  {
                    attrs: {
                      tooltip:
                        "The system only have access to this vehicle VIN",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c("ui-icon", {
                          staticClass:
                            "emobg-color-ink-light emobg-color-ink-hover",
                          attrs: {
                            icon: _vm.ICONS.infoFull,
                            size: _vm.SIZES.small,
                            "data-test-id": "icon",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }