<script>
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'StationList',
  props: {
    stations: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isEmpty,
  },
};
</script>

<template>
  <div class="StationList">
    <div v-if="!isEmpty(stations)">
      <p
        v-for="station in stations"
        :key="station.id"
        class="mb-1"
      >
        {{ `${station.id} ${station.name}` }}
      </p>
    </div>
    <span v-else>{{ FALLBACK_MESSAGE.noData }}</span>
  </div>
</template>

