<script>
import { mapGetters, mapMutations } from 'vuex';

import { MuiSelect, Validate } from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'ExceptionReason',
  components: {
    MuiSelect,
  },
  directives: {
    Validate,
  },
  props: {
    vehicleVin: {
      type: String,
      required: true,
    },
    hasExceptions: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      reason: '',
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.carRental.fleetCheck, ['getExceptionReasons']),
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.carRental.fleetCheck, ['addExceptionVehicle']),
    onReasonSelected(reason) {
      if (!reason.isHeader) {
        this.addExceptionVehicle({
          vin: this.vehicleVin,
          reason: reason.value,
        });
        this.reason = reason.value;
        this.$refs.reasonsSelect.close();
      }
    },
  },
};
</script>

<template>
  <div class="ExceptionReason w-100">
    <MuiSelect
      v-if="hasExceptions"
      ref="reasonsSelect"
      v-model="reason"
      v-validate="{
        isRequired: {
          message: 'Complete this field to continue',
        },
      }"
      placeholder="Select reason"
      class="validate w-100"
      name="exception_reasons"
      data-test-id="exception_reasons-select"
    >
      <div slot="content">
        <div
          v-for="(exceptionReason, reasonIndex) in getExceptionReasons"
          :key="reasonIndex"
          :class="[
            `v1-MuiSelect__list-item v1-MuiSelect__list-item--${SIZES.medium}
            d-flex align-items-center
            cursor-pointer
            px-2`,
            {
              'v1-MuiSelect__list-item--header': exceptionReason.isHeader,
              'v1-MuiSelect__list-item--header--borderTop': exceptionReason.isHeader && reasonIndex > 0,
              'v1-MuiSelect__list-item--selected': exceptionReason.value === exceptionReason
            }
          ]"
          class="emobg-background-color-ground-lighter-hover"
          @click="onReasonSelected(exceptionReason)"
        >
          {{ exceptionReason.label }}
        </div>
      </div>
    </MuiSelect>
    <div v-else>
      No exception detected
    </div>
  </div>
</template>
