var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ExceptionVehiclesPdf p-3",
      attrs: { id: _vm.EXCEPTION_VEHICLES_PDF_ID },
    },
    [
      _c(
        "div",
        { staticClass: "emobg-background-color-white" },
        [
          _c("div", { staticClass: "d-flex justify-content-between mb-4" }, [
            _c(
              "div",
              { staticClass: "emobg-font-weight-bold emobg-font-small" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      `Vehicles with exceptions detected (${_vm.vehicles.length})`
                    ) +
                    " "
                ),
              ]
            ),
            _c("div", { staticClass: "emobg-color-ink emobg-font-small" }, [
              _vm._v(
                " " +
                  _vm._s(
                    `${_vm.formatUtc(
                      _vm.fleetCheckStartedOn,
                      _vm.DATE_FORMAT.defaultExtended,
                      _vm.operatorTimezone
                    )}`
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("MuiTable", {
            staticClass: "mx-0 mb-4",
            attrs: {
              "data-set": _vm.vehicles,
              "no-data-label": _vm.FALLBACK_MESSAGE.noData,
              "content-cells": _vm.pdfContentCells,
              "data-test-id": "vehicles_with_exceptions_pdf-table",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }