var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DownloadPdf" },
    [
      _c(
        "ui-button",
        {
          attrs: {
            color: _vm.GRAYSCALE.inkLight,
            face: _vm.FACES.outline,
            loading: _vm.isDownloading,
            square: "",
            "data-test-id": "download_pdf-button",
          },
          on: { clickbutton: _vm.downloadFleetCheck },
        },
        [
          _c("ui-icon", {
            staticClass: "d-flex flex-column align-center",
            attrs: {
              size: _vm.ICONS_SIZES.small,
              color: _vm.GRAYSCALE.inkLight,
              icon: _vm.ICONS.download,
            },
          }),
        ],
        1
      ),
      !_vm.isEmpty(_vm.fleetCheckDetails)
        ? _c(
            "div",
            {
              staticClass: "DownloadPdf__pdfWrapper",
              staticStyle: { height: "0", overflow: "hidden" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "emobg-background-color-white",
                  attrs: { id: _vm.FLEET_CHECK_PDF_ID },
                },
                [
                  _c("div", { staticClass: "row no-gutters" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-12 px-3 mb-2" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-6 emobg-font-small" }, [
                          _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                            _vm._v(" Fleet check done by: "),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.fleetCheckDetails.summary.author) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-6 text-right emobg-font-small" },
                          [
                            _c(
                              "div",
                              { staticClass: "emobg-font-small mb-1" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "mr-1 emobg-body-2" },
                                  [_vm._v("Start Date/Time:")]
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatUtc(
                                        _vm.fleetCheckDetails.summary.createdOn,
                                        _vm.DATE_FORMAT.defaultExtended,
                                        _vm.operatorTimezone
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "emobg-font-small" }, [
                              _c("span", { staticClass: "mr-1 emobg-body-2" }, [
                                _vm._v("End Date/Time:"),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatUtc(
                                      _vm.fleetCheckDetails.summary.finishedOn,
                                      _vm.DATE_FORMAT.defaultExtended,
                                      _vm.operatorTimezone
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-12 px-3 mb-2" }, [
                      _c("div", { staticClass: "mb-1 emobg-body-2" }, [
                        _vm._v(" Station(s): "),
                      ]),
                      _vm._v(" " + _vm._s(_vm.stationNames) + " "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 px-3 emobg-font-weight-bold emobg-font-small mb-4",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              `Veh. with exceptions detected (${_vm.getVehiclesDataLength(
                                "vehiclesWithException"
                              )}) | Veh. with no exceptions detected (${_vm.getVehiclesDataLength(
                                "vehicles"
                              )})`
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 px-3" },
                      [
                        _c("MuiTable", {
                          staticClass: "mx-0",
                          attrs: {
                            "data-test-id": "vehicles-table",
                            "data-set": _vm.vehicles,
                            "content-cells": _vm.contentCells,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "col-12 emobg-color-white emobg-background-color-corporate-primary-darkest px-3 py-2 mb-4",
      },
      [_c("h4", [_vm._v("Automated fleet check")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }