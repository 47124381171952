var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ExceptionReason w-100" },
    [
      _vm.hasExceptions
        ? _c(
            "MuiSelect",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: {
                    isRequired: {
                      message: "Complete this field to continue",
                    },
                  },
                  expression:
                    "{\n      isRequired: {\n        message: 'Complete this field to continue',\n      },\n    }",
                },
              ],
              ref: "reasonsSelect",
              staticClass: "validate w-100",
              attrs: {
                placeholder: "Select reason",
                name: "exception_reasons",
                "data-test-id": "exception_reasons-select",
              },
              model: {
                value: _vm.reason,
                callback: function ($$v) {
                  _vm.reason = $$v
                },
                expression: "reason",
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                _vm._l(
                  _vm.getExceptionReasons,
                  function (exceptionReason, reasonIndex) {
                    return _c(
                      "div",
                      {
                        key: reasonIndex,
                        staticClass:
                          "emobg-background-color-ground-lighter-hover",
                        class: [
                          `v1-MuiSelect__list-item v1-MuiSelect__list-item--${_vm.SIZES.medium}
          d-flex align-items-center
          cursor-pointer
          px-2`,
                          {
                            "v1-MuiSelect__list-item--header":
                              exceptionReason.isHeader,
                            "v1-MuiSelect__list-item--header--borderTop":
                              exceptionReason.isHeader && reasonIndex > 0,
                            "v1-MuiSelect__list-item--selected":
                              exceptionReason.value === exceptionReason,
                          },
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.onReasonSelected(exceptionReason)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(exceptionReason.label) + " ")]
                    )
                  }
                ),
                0
              ),
            ]
          )
        : _c("div", [_vm._v(" No exception detected ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }