<script>
export default {
  name: 'ExceptionReason',
  props: {
    reason: {
      type: String,
      default: 'No exception detected',
    },
  },
};
</script>

<template>
  <div class="ExceptionReason">
    {{ reason }}
  </div>
</template>

