<script>
import get from 'lodash/get';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isArray from 'lodash/isArray';
import each from 'lodash/each';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import some from 'lodash/some';

import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import { MuiSelect, MuiValidationWrapper, Validate } from '@emobg/motion-ui/v1';
import { MuiModal } from '@emobg/vue-base';
import { MuiApiList } from '@emobg/vue-internal';
import { navigationErrorHandler, TIME_ZONE } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { contentCells, refinementOptions } from './config/fleetCheckContentCells';
import { FLEET_CHECK_ITEMS_PER_PAGE } from './const/fleetCheck.const';

import RefinementDate from './components/RefinementDate';

import carrentalRouter from '../router/CarrentalRouterMap';

export default {
  name: 'FleetCheckView',
  components: {
    MuiModal,
    MuiSelect,
    MuiValidationWrapper,
    MuiApiList,
    RefinementDate,
  },
  directives: {
    Validate,
  },
  data() {
    return {
      pageNumber: 0,
      searchQuery: '',
      isMobileLayout: true,
      isModalVisible: false,
      hasStationWithGeofence: true,
      selectedStationsId: [],
      filters: {
        stations: [],
        startedOnAfter: '',
        startedOnBefore: '',
      },
      modalConfig: {
        title: 'New fleet check',
        isClosable: true,
      },
      labels: { search: 'Search by Reg. number or VIN' },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.carRental.fleetCheck, {
      isLoading: state => state.fleetChecks.STATUS.LOADING,
      fleetCheckList: state => get(state, 'fleetChecks.data.summary') || [],
      totalPages: state => get(state, 'fleetChecks.data.pageCount') || 1,
      stations: state => get(state, 'stations.data'),
    }),
    ...mapGetters(DOMAINS_MODEL.carRental.fleetCheck, ['getStations']),
    fleetCheckQuery() {
      return {
        pageNumber: this.pageNumber,
        pageSize: FLEET_CHECK_ITEMS_PER_PAGE,
        operator: this.activeOperatorUuid,
        vinOrRegistrationNumber: this.searchQuery,
        ...omitBy(this.filters, isEmpty),
      };
    },
    stationRefinmentOptions() {
      const filterStations = map(this.stations, ({ greenwayId, greenwayName }) => ({
        value: greenwayId,
        label: `${greenwayId} - ${greenwayName}`,
      }));
      return refinementOptions({ stations: filterStations });
    },
    activeFiltersCount() {
      const activeFilters = filter(this.filters, filterValue => !isEmpty(filterValue));
      return reduce(activeFilters, (filtersCount, filterValue) => {
        const count = isArray(filterValue) ? filterValue.length : 1;
        return filtersCount + count;
      }, 0);
    },
  },
  watch: {
    pageNumber() {
      this.getFleetCheckList({
        query: this.fleetCheckQuery,
      });
    },
    activeOperatorUuid: {
      handler() {
        this.getStationsList({
          query: {
            operator: this.activeOperatorUuid,
          },
        });
        this.resetFilters();
      },
      immediate: true,
    },
    selectedStationsId() {
      const selectedStations = filter(this.stations, station => includes(this.selectedStationsId, station.greenwayId));
      this.hasStationWithGeofence = isEmpty(selectedStations) ? true : some(selectedStations, station => station.geofences.length);
    },
  },
  mounted() {
    this.$watch(vm => [vm.searchQuery, vm.filters], () => {
      if (!this.pageNumber) {
        this.getFleetCheckList({
          query: this.fleetCheckQuery,
        });
      } else {
        this.pageNumber = 0;
      }
    }, { deep: true });
  },
  created() {
    this.FLEET_CHECK_ITEMS_PER_PAGE = FLEET_CHECK_ITEMS_PER_PAGE;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carRental.fleetCheck, [
      'getFleetCheckList',
      'getStationsList',
    ]),
    ...mapMutations(DOMAINS_MODEL.carRental.fleetCheck, ['setSelectedStationsId']),
    onNewFleetCheck() {
      this.isModalVisible = true;
    },
    onConfirmNewFleetCheck() {
      this.$refs.form.validateAll();
      if (this.$refs.form.areAllValid && this.hasStationWithGeofence) {
        this.setSelectedStationsId(this.selectedStationsId);
        this.$router.push(
          {
            name: carrentalRouter.vehicles.fleetCheck.new,
          },
        ).catch(navigationErrorHandler);
      }
    },
    onCloseModal() {
      this.isModalVisible = false;
      this.hasStationWithGeofence = true;
      this.selectedStationsId = [];
    },
    updateFilters(activeFilters) {
      this.filters.stations = [];
      Object.assign(this.filters, ...activeFilters);
    },
    updateDateFilters(dateFilters) {
      each(dateFilters, dateFilter => {
        this.filters[dateFilter.key] = !isNull(dateFilter.date) ? dateFilter.date.toISOString() : '';
      });
    },
    resetFilters() {
      this.filters.stations = [];
      this.filters.startedOnAfter = '';
      this.filters.startedOnBefore = '';
    },
    contentCells,
    isEmpty,
  },
};
</script>

<template>
  <div
    class="FleetCheckView px-2 mt-3 px-lg-5"
    data-test-id="fleet_check-view"
  >
    <h1 class="pb-4 d-flex justify-content-between">
      Automated fleet check

      <ui-button
        data-test-id="new_fleet_check-button"
        @clickbutton="onNewFleetCheck"
      >
        New fleet check
      </ui-button>
    </h1>
    <div class="emobg-background-color-white emobg-border-1 emobg-border-color-ground emobg-border-radius-small px-3 py-4">
      <h2 class="pb-3 mb-2 emobg-border-bottom-1 emobg-border-color-ground-light">
        History of fleet checks
      </h2>
      <MuiApiList
        :data-set="fleetCheckList"
        :content-cells="contentCells({ operatorTimezone })"
        :refinement-options="stationRefinmentOptions"
        :is-loading="isLoading"
        :active-filters-count="activeFiltersCount"
        :external-pagination="true"
        :page-size="FLEET_CHECK_ITEMS_PER_PAGE"
        :total-records="(totalPages * FLEET_CHECK_ITEMS_PER_PAGE)"
        :no-data-label="FALLBACK_MESSAGE.noData"
        :labels="labels"
        :search="true"
        :no-border="true"
        data-test-id="list"
        @update:page="(page) => pageNumber = page.currentPage"
        @update:query="(query) => searchQuery = query"
        @update:isMobileLayout="(isMobile) => isMobileLayout = isMobile"
        @update:clearFilters="resetFilters"
        @selectedFilters="updateFilters"
      >
        <RefinementDate
          slot="customFiltersBottom"
          :is-mobile-layout="isMobileLayout"
          :clear="!activeFiltersCount"
          @on:dateChaged="updateDateFilters"
        />
      </MuiApiList>
    </div>
    <MuiModal
      v-model="isModalVisible"
      v-bind="modalConfig"
      data-test-id="new_fleet_check-modal"
      @modal-closed="onCloseModal"
    >
      <div slot="body">
        <MuiValidationWrapper
          ref="form"
          class="w-100"
        >
          <div class="d-block pb-2 emobg-body-2">
            Select station(s)
          </div>
          <MuiSelect
            v-model="selectedStationsId"
            v-validate="{
              isRequired: {
                message: 'Select at least one station to continue',
              },
            }"
            :options="getStations"
            :class="{ 'FleetCheckView__geofenceError': !hasStationWithGeofence }"
            multiple
            name="stations"
            placeholder="Select station"
            data-test-id="stations-select"
            class="w-100"
          />
          <div
            v-if="!isEmpty(selectedStationsId) && !hasStationWithGeofence"
            class="mt-1 px-1 emobg-color-danger"
          >
            Select at least one station with a geofence
          </div>
        </MuiValidationWrapper>
      </div>
      <div
        slot="footer"
        class="d-flex justify-content-end p-3"
      >
        <ui-button
          :color="GRAYSCALE.inkLight"
          :face="FACES.text"
          data-test-id="cancel-button"
          @clickbutton="isModalVisible = false"
        >
          Cancel
        </ui-button>
        <ui-button
          data-test-id="confirm-button"
          @clickbutton="onConfirmNewFleetCheck"
        >
          Confirm
        </ui-button>
      </div>
    </MuiModal>
  </div>
</template>
