<script>
export default {
  name: 'VehicleText',
  props: {
    plateNumber: {
      type: String,
      required: true,
    },
    vinNumber: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div class="VehicleText">
    {{ plateNumber || vinNumber }}
  </div>
</template>
