var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "FleetCheckView px-2 mt-3 px-lg-5",
      attrs: { "data-test-id": "fleet_check-view" },
    },
    [
      _c(
        "h1",
        { staticClass: "pb-4 d-flex justify-content-between" },
        [
          _vm._v(" Automated fleet check "),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "new_fleet_check-button" },
              on: { clickbutton: _vm.onNewFleetCheck },
            },
            [_vm._v(" New fleet check ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "emobg-background-color-white emobg-border-1 emobg-border-color-ground emobg-border-radius-small px-3 py-4",
        },
        [
          _c(
            "h2",
            {
              staticClass:
                "pb-3 mb-2 emobg-border-bottom-1 emobg-border-color-ground-light",
            },
            [_vm._v(" History of fleet checks ")]
          ),
          _c(
            "MuiApiList",
            {
              attrs: {
                "data-set": _vm.fleetCheckList,
                "content-cells": _vm.contentCells({
                  operatorTimezone: _vm.operatorTimezone,
                }),
                "refinement-options": _vm.stationRefinmentOptions,
                "is-loading": _vm.isLoading,
                "active-filters-count": _vm.activeFiltersCount,
                "external-pagination": true,
                "page-size": _vm.FLEET_CHECK_ITEMS_PER_PAGE,
                "total-records":
                  _vm.totalPages * _vm.FLEET_CHECK_ITEMS_PER_PAGE,
                "no-data-label": _vm.FALLBACK_MESSAGE.noData,
                labels: _vm.labels,
                search: true,
                "no-border": true,
                "data-test-id": "list",
              },
              on: {
                "update:page": (page) => (_vm.pageNumber = page.currentPage),
                "update:query": (query) => (_vm.searchQuery = query),
                "update:isMobileLayout": (isMobile) =>
                  (_vm.isMobileLayout = isMobile),
                "update:clearFilters": _vm.resetFilters,
                selectedFilters: _vm.updateFilters,
              },
            },
            [
              _c("RefinementDate", {
                attrs: {
                  slot: "customFiltersBottom",
                  "is-mobile-layout": _vm.isMobileLayout,
                  clear: !_vm.activeFiltersCount,
                },
                on: { "on:dateChaged": _vm.updateDateFilters },
                slot: "customFiltersBottom",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "MuiModal",
        _vm._b(
          {
            attrs: { "data-test-id": "new_fleet_check-modal" },
            on: { "modal-closed": _vm.onCloseModal },
            model: {
              value: _vm.isModalVisible,
              callback: function ($$v) {
                _vm.isModalVisible = $$v
              },
              expression: "isModalVisible",
            },
          },
          "MuiModal",
          _vm.modalConfig,
          false
        ),
        [
          _c(
            "div",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _c(
                "MuiValidationWrapper",
                { ref: "form", staticClass: "w-100" },
                [
                  _c("div", { staticClass: "d-block pb-2 emobg-body-2" }, [
                    _vm._v(" Select station(s) "),
                  ]),
                  _c("MuiSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          isRequired: {
                            message: "Select at least one station to continue",
                          },
                        },
                        expression:
                          "{\n            isRequired: {\n              message: 'Select at least one station to continue',\n            },\n          }",
                      },
                    ],
                    staticClass: "w-100",
                    class: {
                      FleetCheckView__geofenceError:
                        !_vm.hasStationWithGeofence,
                    },
                    attrs: {
                      options: _vm.getStations,
                      multiple: "",
                      name: "stations",
                      placeholder: "Select station",
                      "data-test-id": "stations-select",
                    },
                    model: {
                      value: _vm.selectedStationsId,
                      callback: function ($$v) {
                        _vm.selectedStationsId = $$v
                      },
                      expression: "selectedStationsId",
                    },
                  }),
                  !_vm.isEmpty(_vm.selectedStationsId) &&
                  !_vm.hasStationWithGeofence
                    ? _c(
                        "div",
                        { staticClass: "mt-1 px-1 emobg-color-danger" },
                        [
                          _vm._v(
                            " Select at least one station with a geofence "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-end p-3",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "ui-button",
                {
                  attrs: {
                    color: _vm.GRAYSCALE.inkLight,
                    face: _vm.FACES.text,
                    "data-test-id": "cancel-button",
                  },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isModalVisible = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "ui-button",
                {
                  attrs: { "data-test-id": "confirm-button" },
                  on: { clickbutton: _vm.onConfirmNewFleetCheck },
                },
                [_vm._v(" Confirm ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }