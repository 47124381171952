import get from 'lodash/get';
import toLower from 'lodash/toLower';

import { FALLBACK_MESSAGE } from '@emobg/web-utils';

import VehicleText from '../components/VehicleText';
import ExceptionReason from '../components/ExceptionReason';
import { GEOFENCE_DATA } from '../New/const/newFleetCheck.const';

export const contentCells = [
  {
    title: 'Vehicle identifier',
    component: VehicleText,
    props: ({ regNumber, vin }) => ({ plateNumber: regNumber, vinNumber: vin }),
    width: 80,
  },
  {
    title: 'Model',
    property: 'model',
    width: 150,
  },
  {
    title: 'Geof. data',
    property: 'positionState',
    transformValue: value => GEOFENCE_DATA[toLower(value)] || FALLBACK_MESSAGE.dash,
    width: 100,
  },
  {
    title: 'GWY data',
    property: 'greenwayData',
    width: 80,
  },
  {
    title: 'Exception reasons',
    component: ExceptionReason,
    props: result => ({
      reason: get(result, 'reason'),
    }),
    width: 200,
  },
];
