<script>
import VehicleLink from '../../../components/VehicleLink.vue';

export default {
  name: 'VehicleIdentifier',
  components: {
    VehicleLink,
  },
  props: {
    plateNumber: {
      type: String,
      required: true,
    },
    vinNumber: {
      type: String,
      required: true,
    },
    showLink: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<template>
  <div class="VehicleIdentifier">
    <div v-if="plateNumber">
      <VehicleLink
        v-if="showLink"
        :plate-number="plateNumber"
        :vin-number="vinNumber"
        target="_blank"
      />
      <span v-else>{{ plateNumber }}</span>
    </div>
    <div v-else>
      <span class="mr-1">{{ vinNumber }}</span>
      <ui-tooltip
        v-if="showLink"
        tooltip="The system only have access to this vehicle VIN"
      >
        <div class="d-flex align-items-center">
          <ui-icon
            :icon="ICONS.infoFull"
            :size="SIZES.small"
            class="emobg-color-ink-light emobg-color-ink-hover"
            data-test-id="icon"
          />
        </div>
      </ui-tooltip>
    </div>
  </div>
</template>
