<script>
import get from 'lodash/get';

import { mapState } from 'vuex';
import {
  DATE_FORMAT,
  formatUtc,
  TIME_ZONE,
} from '@emobg/web-utils';
import { MuiTable } from '@emobg/vue-base';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { pdfContentCells } from '../config/newFleetCheckContentCells';
import { EXCEPTION_VEHICLES_PDF_ID } from '../const/newFleetCheck.const';

export default {
  name: 'ExceptionVehiclesPdf',
  components: {
    MuiTable,
  },
  props: {
    vehicles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDownloading: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carRental.fleetCheck, {
      fleetCheckStartedOn: state => get(state, 'ongoingFleetCheck.data.startedOn'),
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
  },
  created() {
    this.EXCEPTION_VEHICLES_PDF_ID = EXCEPTION_VEHICLES_PDF_ID;
    this.DATE_FORMAT = DATE_FORMAT;
    this.pdfContentCells = pdfContentCells;
  },
  methods: {
    formatUtc,
  },
};
</script>

<template>
  <div
    :id="EXCEPTION_VEHICLES_PDF_ID"
    class="ExceptionVehiclesPdf p-3"
  >
    <div class="emobg-background-color-white">
      <div class="d-flex justify-content-between mb-4">
        <div class="emobg-font-weight-bold emobg-font-small">
          {{ `Vehicles with exceptions detected (${vehicles.length})` }}
        </div>
        <div class="emobg-color-ink emobg-font-small">
          {{ `${formatUtc(fleetCheckStartedOn, DATE_FORMAT.defaultExtended, operatorTimezone)}` }}
        </div>
      </div>
      <MuiTable
        :data-set="vehicles"
        :no-data-label="FALLBACK_MESSAGE.noData"
        :content-cells="pdfContentCells"
        data-test-id="vehicles_with_exceptions_pdf-table"
        class="mx-0 mb-4"
      />
    </div>
  </div>
</template>

<style lang="scss">
.ExceptionVehiclesPdf {
  .MuiTable {
    &__rowWrapper > .col {
      padding: 8px !important;
      font-size: 12px;
    }

    &__visibleCellsWrapper > div {
      padding: 8px !important;
      font-size: 12px;
    }
  }
}
</style>

