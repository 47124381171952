export const GEOFENCE_TYPES = Object.freeze({
  in: 'in',
  out: 'out',
  unknown: 'unknown',
});

export const GEOFENCE_DATA = Object.freeze({
  in: 'Inside station',
  out: 'Outside station',
  unknown: 'Unknown',
});

export const EXCEPTION_VEHICLES_PDF_ID = 'exceptionVehiclesPdfContent';

export const EXCEPTION_VEHICLES_SORT_OPTIONS = Object.freeze({
  [GEOFENCE_TYPES.out]: -1,
  [GEOFENCE_TYPES.in]: 1,
});
