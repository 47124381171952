import get from 'lodash/get';

import { DATE_FORMAT, TIME_ZONE, formatUtc } from '@emobg/web-utils';

import StationList from '../components/StationList';
import DownloadPdf from '../components/DownloadPdf';

export const contentCells = ({ operatorTimezone = TIME_ZONE.default }) => [
  {
    title: 'Station(s)',
    component: StationList,
    props: result => ({
      stations: get(result, 'stations', []),
    }),
    width: 460,
  },
  {
    title: 'Fleet check done by',
    property: 'author',
    width: 270,
  },
  {
    title: 'Start Date/Time',
    property: 'createdOn',
    width: 180,
    transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
  },
  {
    title: 'Download PDF',
    component: DownloadPdf,
    props: result => ({
      fleetCheckId: get(result, 'id'),
    }),
    width: 150,
  },
];

export const refinementOptions = ({ stations = [] }) => [
  {
    type: 'refinementCheckbox',
    props: {
      title: 'Station',
      attributeName: 'stations',
    },
    customFilters: stations,
  },
];
