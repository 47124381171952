<script>
import get from 'lodash/get';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';
import sortBy from 'lodash/sortBy';

import { mapActions, mapMutations, mapState } from 'vuex';

import {
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { MuiModal, MuiTable } from '@emobg/vue-base';
import {
  DATE_FORMAT,
  formatUtc,
  navigationErrorHandler,
  TIME_ZONE,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { downloadPdf } from '@/domains/Carrental/utils/downloadPdf';
import { NOTIFICATION_TYPES } from '@/constants/notifications';

import { contentCells } from './config/newFleetCheckContentCells';
import { EXCEPTION_VEHICLES_PDF_ID, EXCEPTION_VEHICLES_SORT_OPTIONS } from './const/newFleetCheck.const';
import carrentalRouter from '../../router/CarrentalRouterMap';

import ExceptionVehiclesPdf from './components/ExceptionVehiclesPdf.vue';

export default {
  name: 'NewFleetCheckView',
  components: {
    MuiTable,
    MuiModal,
    MuiValidationWrapper,
    ExceptionVehiclesPdf,
  },
  directives: {
    Validate,
  },
  beforeRouteLeave(to, _from, next) {
    if (!this.isForcedNavigation && !this.isFleetCheckSaved && !isEmpty(this.selectedStationsId)) {
      this.urlName = to.name;
      this.isLeaveModalOpen = true;
    } else {
      next();
    }
  },
  data() {
    return {
      isTableValid: true,
      isFleetCheckSaved: false,
      isForcedNavigation: false,
      isLeaveModalOpen: false,
      isConfirmModalOpen: false,
      isDownloading: false,
      leaveModalConfig: {
        title: 'Leave the fleet check?',
        isClosable: true,
      },
      confirmModalConfig: {
        title: 'Confirm the fleet check?',
        isClosable: true,
      },
      urlName: '',
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => get(state, 'operators.active.uuid'),
      operatorTimezone: state => get(state, 'operators.active.timezone') || TIME_ZONE.default,
      userEmail: state => get(state, 'user.data.email'),
    }),
    ...mapState(DOMAINS_MODEL.carRental.fleetCheck, {
      stations: state => get(state, 'stations.data') || [],
      selectedStationsId: state => get(state, 'selectedStationsId') || [],
      isFleetCheckLoading: state => get(state, 'ongoingFleetCheck.STATUS.LOADING', false),
      fleetCheckStartedOn: state => get(state, 'ongoingFleetCheck.data.startedOn'),
      fleetCheckId: state => get(state, 'ongoingFleetCheck.data.id'),
      vehiclesWithExceptions: state => {
        const exceptionVehicles = get(state, 'ongoingFleetCheck.data.exceptionVehicles') || [];
        return sortBy(exceptionVehicles, vehicle => EXCEPTION_VEHICLES_SORT_OPTIONS[toLower(vehicle.positionState)] || 0);
      },
      vehiclesWithoutExceptions: state => get(state, 'ongoingFleetCheck.data.vehicles') || [],
      exceptionVehicles: state => get(state, 'exceptionVehicles') || [],
      isSubmittingFleetCheck: state => get(state, 'fleetCheck.STATUS.LOADING', false),
      fleetCheckStatus: state => get(state, 'fleetCheck.STATUS') || {},
      ongoingFleetCheckStatus: state => get(state, 'ongoingFleetCheck.STATUS') || {},
    }),
    stationNames() {
      const selectedStationsArray = filter(this.stations, station => includes(this.selectedStationsId, station.greenwayId));
      return map(selectedStationsArray, station => `${station.greenwayId} ${station.greenwayName}`).join(', ');
    },
  },
  watch: {
    activeOperatorUuid() {
      this.redirectToFleetCheckList();
    },
  },
  beforeDestroy() {
    this.clearNewFleetCheckData();
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
    this.vehicleWithExceptionContentCells = contentCells();
    this.vehicleWithoutExceptionContentCells = contentCells({ hasExceptions: false });
  },
  async mounted() {
    if (isEmpty(this.selectedStationsId)) {
      this.redirectToFleetCheckList();
    } else {
      await Promise.all([
        this.postFleetCheck({
          operatorId: this.activeOperatorUuid,
          stations: this.selectedStationsId,
          author: this.userEmail,
        }),
        this.getReasons(),
      ]);
      if (this.ongoingFleetCheckStatus.ERROR) {
        // If the initial endpoint fails we allow to go to another page and do not show the preventing modal
        this.isFleetCheckSaved = true;
      }
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carRental.fleetCheck, [
      'getReasons',
      'postFleetCheck',
      'patchFleetCheck',
    ]),
    ...mapMutations(DOMAINS_MODEL.carRental.fleetCheck, ['clearNewFleetCheckData']),
    redirectToFleetCheckList() {
      this.$router.push(
        {
          name: carrentalRouter.vehicles.fleetCheck.index,
        },
      ).catch(navigationErrorHandler);
    },
    onConfirmNewFleetCheck() {
      this.$refs.form.validateAll();
      this.isTableValid = get(this.$refs, 'form.areAllValid', true);
      if (this.$refs.form.areAllValid) {
        this.isConfirmModalOpen = true;
      }
    },
    async doConfirmFleetCheck() {
      await this.patchFleetCheck({
        fleetCheckId: this.fleetCheckId,
        updateFleetCheckData: {
          exceptions: this.exceptionVehicles,
        },
      });
      this.isConfirmModalOpen = false;
      if (!this.fleetCheckStatus.ERROR) {
        this.isFleetCheckSaved = true;
        this.$notify({
          type: NOTIFICATION_TYPES.success,
          message: 'Fleet inventory done!',
        });
        this.redirectToFleetCheckList();
      }
    },
    async exportExceptionList() {
      this.isDownloading = true;
      const filename = `vehicle_exceptions-${formatUtc(this.fleetCheckStartedOn, DATE_FORMAT.dateTime, this.operatorTimezone).replace(' ', '-')}`;
      await downloadPdf(EXCEPTION_VEHICLES_PDF_ID, filename);
      this.isDownloading = false;
    },
    navigate() {
      this.isLeaveModalOpen = false;
      this.isForcedNavigation = true;
      this.$router.push({ name: this.urlName }).catch(navigationErrorHandler);
    },
    isEmpty,
    formatUtc,
  },
};
</script>

<template>
  <div
    class="NewFleetCheckView px-2 mt-3 px-lg-5"
    data-test-id="fleet-check-view"
  >
    <h1 class="pb-1">
      Fleet check
    </h1>
    <div class="emobg-color-ink-light emobg-font-weight-bold mb-1">
      {{ `Start Date/Time: ${fleetCheckStartedOn ? formatUtc(fleetCheckStartedOn, DATE_FORMAT.defaultExtended, operatorTimezone) : FALLBACK_MESSAGE.noData}` }}
    </div>
    <div class="emobg-color-ink-light emobg-font-weight-bold mb-3">
      {{ `Stations: ${stationNames}` }}
    </div>
    <ui-alert
      :color="COLORS.primary"
      :icon="ICONS.infoFull"
      class="d-block mb-4"
      data-test-id="search_help_text-alert"
    >
      <p class="emobg-font-small emobg-color-ink">
        <span class="emobg-font-weight-semibold">Search</span>: Use the keyboard shortcut <span class="emobg-font-weight-semibold">Ctrl + F</span> to search a registration number or any other text in this page
      </p>
    </ui-alert>
    <div class="px-3 py-4 mb-3 emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-white">
      <div class="d-flex justify-content-between mb-3">
        <div class="emobg-font-weight-bold emobg-font-default">
          {{ isEmpty(vehiclesWithExceptions) ? 'No vehicles with exceptions detected' : `Vehicles with exceptions detected (${vehiclesWithExceptions.length})` }}
        </div>
        <ui-button
          :color="GRAYSCALE.inkLight"
          :face="FACES.outline"
          :size="SIZES.small"
          :loading="isDownloading"
          :disabled="isEmpty(vehiclesWithExceptions)"
          data-test-id="vehicle_with_exceptions_export-button"
          class="ExportCsv"
          @clickbutton="exportExceptionList"
        >
          <ui-icon
            :icon="ICONS.download"
            :size="SIZES.small"
            hover
          />
          <div class="ml-3">
            Export exception list
          </div>
        </ui-button>
      </div>
      <ui-loader v-if="isFleetCheckLoading" />
      <MuiValidationWrapper
        v-else
        ref="form"
        class="w-100"
      >
        <MuiTable
          v-if="!isEmpty(vehiclesWithExceptions)"
          :data-set="vehiclesWithExceptions"
          :no-data-label="FALLBACK_MESSAGE.noData"
          :content-cells="vehicleWithExceptionContentCells"
          data-test-id="vehicles_with_exceptions-table"
          class="mx-0 mb-4"
        />
        <div class="justify-content-end d-flex">
          <ui-alert
            v-if="!isTableValid"
            :color="COLORS.danger"
            :icon="ICONS.infoFull"
            class="mr-3"
          >
            <span class="emobg-font-small">
              Please complete all missing fields
            </span>
          </ui-alert>
          <ui-button
            data-test-id="confirm-button"
            @clickbutton="onConfirmNewFleetCheck"
          >
            Confirm fleet check
          </ui-button>
        </div>
      </MuiValidationWrapper>
      <div style="height: 0; overflow: hidden;">
        <ExceptionVehiclesPdf :vehicles="vehiclesWithExceptions" />
      </div>
    </div>
    <div class="px-3 py-4 emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-white">
      <div class="emobg-font-weight-bold emobg-font-default mb-3">
        {{ isEmpty(vehiclesWithoutExceptions) ? 'No vehicles without exceptions detected' : `Vehicles with no exceptions detected (${vehiclesWithoutExceptions.length})` }}
      </div>
      <MuiTable
        :data-set="vehiclesWithoutExceptions"
        :content-cells="vehicleWithoutExceptionContentCells"
        :no-data-label="FALLBACK_MESSAGE.noData"
        :is-loading="isFleetCheckLoading"
        data-test-id="vehicles_with_no_exceptions-table"
        class="mx-0 mb-4"
      />
    </div>
    <MuiModal
      v-model="isLeaveModalOpen"
      :size="SIZES.small"
      data-test-id="leave_page_feedback-modal"
      v-bind="leaveModalConfig"
      @modal-closed="isLeaveModalOpen = false"
    >
      <template #body>
        <div
          data-test-id="warning"
          class="py-3 emobg-color-ink-light emobg-font-line-height-large"
        >
          Your changes will not be saved and you will have to start the Fleet check again.
        </div>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end p-3">
          <ui-button
            :color="GRAYSCALE.inkLight"
            :face="FACES.text"
            data-test-id="cancel-button"
            @clickbutton="isLeaveModalOpen = false"
          >
            Cancel
          </ui-button>
          <ui-button
            :color="COLORS.danger"
            data-test-id="navigate-button"
            class="ml-4"
            @clickbutton="navigate"
          >
            Yes, leave
          </ui-button>
        </div>
      </template>
    </MuiModal>
    <MuiModal
      v-model="isConfirmModalOpen"
      :size="SIZES.small"
      data-test-id="leave_page_feedback-modal"
      v-bind="confirmModalConfig"
      @modal-closed="isConfirmModalOpen = false"
    >
      <template #body>
        <div
          data-test-id="warning"
          class="py-3 emobg-color-ink-light emobg-font-line-height-large"
        >
          Once confirmed you won’t be able to edit or delete the created fleet check.
        </div>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end p-3">
          <ui-button
            :color="GRAYSCALE.inkLight"
            :face="FACES.text"
            data-test-id="cancel-button"
            @clickbutton="isConfirmModalOpen = false"
          >
            Cancel
          </ui-button>
          <ui-button
            :loading="isSubmittingFleetCheck"
            data-test-id="navigate-button"
            class="ml-4"
            @clickbutton="doConfirmFleetCheck"
          >
            Confirm
          </ui-button>
        </div>
      </template>
    </MuiModal>
  </div>
</template>
